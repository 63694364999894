<template>
  <div id="construction-page">
    <div class="inner-cons">
      <div
        class="intro cvr-bg"
        style="background-image: url('/img/dummy/cons/1.jpg')"
      >
        <div class="wrapper rowflex">
          <div class="caption">
            <h2>
              Rented a new place? Bought a new property? Or looking to upgrade
              and spruce things up?
            </h2>
            <div class="format-text">
              <p>
                We are here to help you! No more incompetent workers, no more
                having to supervise 5 hours a day and no more getting ripped off
                from agreed specifications. Building, moving, renovating and
                furnishing a new property should be fun and exciting, so let us
                do all the hard work while you enjoy.
              </p>
            </div>
            <!-- end of format etxt -->
          </div>
          <!-- end of caption -->

          <div class="layer">
            <h2>Tell Us What You Need!</h2>
            <form @submit.prevent="submitForm">
              <fieldset>
                <label>
                  Type of Work (choose more than one where applicable)
                </label>
                <div class="field-check">
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Building"
                      v-model="form.workType"
                    />
                    <span>Building</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Renovating"
                      v-model="form.workType"
                    />
                    <span>Renovating</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Interior Design"
                      v-model="form.workType"
                    />
                    <span>Interior Design</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Moving"
                      v-model="form.workType"
                    />
                    <span>Moving</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Furnishing"
                      v-model="form.workType"
                    />
                    <span>Furnishing</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="type"
                      value="Other"
                      v-model="form.workType"
                    />
                    <span>
                      Other: <input type="text" v-model="form.workTypeOther" />
                    </span>
                  </label>
                </div>
                <label style="color: red" v-if="errorWorkType">
                  {{ errorWorkTypeMsg }}
                </label>
                <!-- end of field check -->
              </fieldset>
              <fieldset>
                <label>Type of Property </label>
                <div class="field-select">
                  <select v-model="form.propertyType">
                    <option
                      v-for="(type, index) in propertyType"
                      :key="index"
                      :value="type.name"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                </div>
                <!-- end of select -->
              </fieldset>
              <button
                type="button"
                class="button"
                @click.prevent="redirectToWA"
              >
                Contact Our Customer Agent
              </button>
              <!-- <template v-if="$store.state.userdata">
                <button type="submit" class="button">
                  Contact Our Customer Agent
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="button"
                  data-src="#login-register"
                  data-fancybox
                >
                  Submit
                </button>
              </template> -->
            </form>
          </div>
          <!-- end of layer -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of intro -->

      <div class="service">
        <div class="wrapper">
          <div class="hentry">
            <h2>
              What Construction Services Do We Provide <br />& How Do We Charge
            </h2>
            <div class="format-text">
              <p>We have 3 tiers of services</p>
            </div>
            <!-- end of format text -->
          </div>
          <!-- end of hentry -->

          <div class="rowflex">
            <div class="item">
              <h3>Rubii Supervision</h3>
              <div class="format-text">
                <p>
                  Rubii will help supervise your project’s progress and
                  execution
                </p>
              </div>
              <span>
                4% of total pricing or Rp. 3,000,000 per month, whichever is
                higher
              </span>
              <ul>
                <li>User will search for their own contractors and vendors</li>
                <li>User will negotiate quotes</li>
                <li>
                  <b>1-2 days a week</b> on-site supervision by our Construction
                  Supervisor
                </li>
                <li>
                  Rubii will inform contractors of any errors, alert the users
                  and provide users with a detailed report every 2 weeks
                </li>
              </ul>
            </div>
            <!-- end of item -->
            <div class="item">
              <h3>Rubii Control</h3>
              <div class="format-text">
                <p>
                  Rubii will provide vendors and feedback to ensure optimal
                  results
                </p>
              </div>
              <span>
                6% of Total Pricing or Rp. 4,500,000 per month, whichever is
                higher
              </span>
              <ul>
                <li>Rubii will provide suitable contractors and vendors</li>
                <li>Rubii will help to negotiate quotes</li>
                <li>
                  <b>1-2 days a week</b> on-site supervision by our Construction
                  Supervisor
                </li>
                <li>
                  Rubii will alert and inform contractors of any errors, report
                  to users and provide a detailed report weekly
                </li>
              </ul>
            </div>
            <!-- end of item -->
            <div class="item">
              <h3>Rubii Project</h3>
              <div class="format-text">
                <p>
                  Rubii will execute the project and ensure quality, punctuality
                  and agreed costs
                </p>
              </div>
              <span>
                Custom
              </span>
              <ul>
                <li>Rubii will act as the contractor and vendor</li>
                <li>Rubii will offer quotes</li>
                <li>
                  Rubii’s representative will work on the project
                  <b>every workday</b>
                </li>
                <li>
                  Rubii will report to users, provide a detailed report weekly,
                  guarantee that there will be no major error and be responsible
                  for delays and cost-overruns
                </li>
              </ul>
            </div>
            <!-- end of item -->
          </div>
          <!-- end of rowflex -->

          <!-- <div class="centered">
            <a href="#" class="button">Show More Details</a>
          </div> -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of service -->

      <div class="info">
        <div class="wrapper">
          <h2>What are our commitments?</h2>
          <div class="rowflex">
            <div class="caption">
              <div class="format-text">
                <p>
                  Regardless of which tier you choose, we are committed to
                  provide you with comfort and peace of mind. Building, moving,
                  renovating and/or furnishing a property should not be a
                  massive headache and an additional burden for users, it should
                  be a seamless and exciting process! Rubii will allow you to do
                  just that.
                </p>
                <p>
                  We operate at the highest level of integrity and care,
                  ensuring that all the works are as per the agreed
                  specifications and making sure that your property and
                  belongings are well taken care of. We do not and will not
                  tolerate any unprofessional behaviors, unethical conduct or
                  users being intentionally cheated on by contractors, vendors
                  or our representatives.
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <div class="layer">
              <h3>How Can I Start & Get a Quote</h3>
              <div class="format-text">
                <p>
                  Contact your Customer Success Agent now! If you do not have
                  one, message us below
                </p>
              </div>
              <!-- end of format text -->
              <a href="https://wa.me/+6287708779768" class="button btn-hollow">
                Contact a Customer Success Agent
              </a>
            </div>
            <!-- end of layer -->
          </div>
          <!-- end of rowflex -->
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of info -->
    </div>
    <!-- end of inner cons -->
  </div>
</template>

<script>
export default {
  name: "Construction",
  metaInfo: {
    title: "Construction Service"
  },
  data() {
    return {
      propertyType: [],
      errorWorkType: false,
      errorWorkTypeMsg: null,
      form: {
        workType: [],
        workTypeOther: null,
        propertyType: null
      }
    };
  },
  async mounted() {
    this.getPropertyType();
  },
  methods: {
    redirectToWA() {
      window.location.href = "https://wa.me/+6287708779768";
    },
    getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
          this.form.propertyType = this.propertyType[0].name;
        });
    },
    submitForm() {
      if (this.form.workType.length == 0) {
        this.errorWorkType = true;
        this.errorWorkTypeMsg = "choose at least 1 work type";
        return false;
      } else {
        if (
          this.form.workType.findIndex(item => item == "Other") >= 0 &&
          this.form.workTypeOther == null
        ) {
          this.errorWorkType = true;
          this.errorWorkTypeMsg = "enter other type of work";
          return false;
        }
      }
      this.errorWorkType = false;
      this.errorWorkTypeMsg = null;
      this.form.workType = this.form.workType.map(item => {
        if (item == "Other") {
          return this.form.workTypeOther;
        } else {
          return item;
        }
      });
      this.$axios
        .post(`/constructions`, {
          workType: this.form.workType,
          propertyType: this.form.propertyType
        })
        .then(response => {
          this.form.workType = [];
          this.form.workTypeOther = null;
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
